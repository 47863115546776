import { ReactComponent as TiktokIcon } from "../../assets/images/icons/tiktok-icon.svg";
import { ReactComponent as TelegramIcon } from "../../assets/images/icons/telegram-icon.svg";
import { ReactComponent as InstagramIcon } from "../../assets/images/icons/instagram-icon.svg";
import { ReactComponent as Logo } from "../../assets/images/logo/logo.svg";
import SubscribeForm from "../SubscribeForm/SubscribeForm";
import { motion } from "framer-motion";

function Footer({
  ourProjectsRef,
  aboutUs,
  ourServicesRef,
  catalogRef,
  typesOfStoneRef,
  reviewsRef,
}) {
  const nav = [
    { name: "Ритуальні вироби", elementId: catalogRef },
    { name: "Наші роботи", elementId: ourServicesRef },
    { name: "Види каменю", elementId: typesOfStoneRef },
    { name: "Відгуки", elementId: reviewsRef },
    { name: "Про компанію", elementId: aboutUs },
  ];

  const handleNavClick = (elementId) => {
    elementId.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <footer className="flex flex-col items-center border-t border-solid border-custom-gray-100 pt-10 pb-4 md:flex-row md:flex-wrap md:items-start md:justify-between max-w-[1920px]">
      <div className="flex justify-center mb-8 md:w-1/3 md:order-5 md:justify-start md:max-w-[500px]">
        <div className="border border-solid border-custom-gray-100  p-3 w-max rounded-full mx-2 md:ml-0">
          <TelegramIcon />
        </div>
        <div className="border border-solid border-custom-gray-100  p-3 w-max rounded-full mx-2">
          <TiktokIcon />
        </div>
        <div className="border border-solid border-custom-gray-100  p-3 w-max rounded-full mx-2">
          <InstagramIcon />
        </div>
      </div>
      <div className="mb-8 flex flex-col items-center md:w-1/3 md:order-1 md:items-start md:max-w-[500px]">
        <h4 className="text-h4 text-custom-black mb-4 md:mb-6">Навігація</h4>
        <div className="flex flex-wrap justify-between">
          {nav.map((item, index) => (
            <motion.p
              key={index}
              onClick={() => handleNavClick(item.elementId)}
              className="cursor-pointer text-center text-medium opacity-70 mb-2 w-1/2 md:text-left"
              initial={{ color: "#2D2B28" }}
              whileHover={{ color: "#F4AD24", opacity: 1 }}
              whileTap={{ color: "#F4AD24", opacity: 1 }}
            >
              {item.name}
            </motion.p>
          ))}
        </div>
      </div>
      <div className="mb-8 flex flex-col items-center md:w-1/3 md:order-1 md:items-start md:max-w-[500px]">
        <h4 className="text-h4 text-custom-black mb-4 md:mb-6">Контакти</h4>
        <div className="text-center md:text-left md:flex md:flex-wrap">
          <p className="text-custom-black text-medium opacity-70 mb-2 md:w-1/2">
            marblestyle2024@gmail.com
          </p>
          <p className="text-custom-black text-medium opacity-70 mb-2 md:w-1/2">
            www.marblestyle.com.ua
          </p>
          <p className="text-custom-black text-medium opacity-70 mb-2 md:w-1/2">
            UK: +38 068 79 19 762
          </p>
          <p className="text-custom-black text-medium opacity-70 mb-2 md:w-1/2">
            Ukraine, Kyiv
          </p>
        </div>
      </div>
      <div className="mb-8 w-full md:w-1/3 md:order-1 md:max-w-[500px]">
        <h4 className="text-h4 mb-4 text-center text-custom-black md:mb-6 md:w-max">
          Підписка
        </h4>
        <SubscribeForm />
      </div>
      <div className="mb-8 md:w-1/3 md:order-6 md:max-w-[500px]">
        <p className="text-custom-black text-center max-w-[285px] text-small opacity-70 md:text-start">
          Ми створюємо атмосферу вишуканості та комфорту в вашому просторі.
        </p>
      </div>
      <div className="mb-8 flex items-center md:w-1/3 md:order-4 md:max-w-[500px]">
        <Logo className="w-8 h-8" />
        <div className="ml-5">
          <p className="text-custom-black text-editional opacity-70">
            2024 marble style
          </p>
          <p className="text-custom-black text-editional opacity-70">
            © All rights reserved
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
