import React from "react";

import GearIcon from './../../assets/images/icons/gear-icon.svg';
import MoneyIcon from './../../assets/images/icons/money-bill-icon.svg';
import WrenchGearIcon from '../../assets/images/icons/wrench.svg';
import HandBoxIcon from '../../assets/images/icons/hand.svg';
import DiscountGearIcon from '../../assets/images/icons/discount-icon.svg';
import TruckIcon from './../../assets/images/icons/track-icon.svg';

const OurAdvantagesSection = () => {
    const services = [
        { icon: GearIcon, title: "РОЗРОБКА МАКЕТУ" },
        { icon: MoneyIcon, title: "БЕЗКОШТОВНИЙ ПРОРАХУНОК" },
        { icon: WrenchGearIcon, title: "ВЛАСНЕ ВИРОБНИЦТВО" },
        { icon: HandBoxIcon, title: "ПРЯМИЙ ПОСТАЧАЛЬНИК" },
        { icon: DiscountGearIcon, title: "ЗНИЖКИ ВІЙСЬКОВИМ" },
        { icon: TruckIcon, title: "ДОСТАВКА ТА МОНТАЖ" }
    ];

    return (
        <div className="flex flex-row flex-wrap sm:justify-between mt-8 sm:mt-16">
            {services.map((service, index) => (
                <div key={index} className="w-[140px] flex flex-col items-center text-center mb-8 mx-auto">
                    <div className="flex items-center justify-center bg-[#6E5C40] rounded-full mb-4 w-16 h-16">
                        <img src={service.icon} className="w-2/3 h-2/3"/>
                    </div>
                    <div className="text-sm text-black">{service.title}</div>
                </div>
            ))}
        </div>
    );
};

export default OurAdvantagesSection;
