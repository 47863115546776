import {motion} from "framer-motion";
import NavigationArrow from "../NavigationArrow/NavigationArrow";
// import RatingCard from "../RatingCard/RatingCard";
import BigStonePhoto from "../../assets/images/photos/big-stone.png";
import FireplacePhoto from "../../assets/images/photos/fireplace.png";
import styles from "./HeroSection.module.css";
import React from "react";
import { ReactComponent as CheckIcon } from "../../assets/images/icons/check.svg";
import OurAdvantagesSection from "../OurAdvantagesSection/OurAdvantagesSection";

function HeroSection() {
    return (
        <motion.section
            initial={{y: "150%", opacity: 0.5}}
            animate={{y: 0, opacity: 1}}
            transition={{duration: 1, ease: "easeOut"}}
        >
            <div className="flex-col items-center pt-28 lg:pt-40 flex lg:items-start lg:justify-between lg:flex-row">
                <div className="max-w-screen-sm w-full z-20">
                    <h1 className="text-h1 text-custom-black mb-9 text-center lg:text-start">
                        MARBLE STYLE
                    </h1>
                    <div className={'flex items-center mb-6 pb-2 border-b border-solid border-[#6E5C40]'}>
                        <CheckIcon className="w-10 h-10 mr-2"/>
                        <h4 className={'text-btn lg:text-h4'}>БІЛЬШЕ 1000 ВИКОНАНИХ ПРОЄКТІВ</h4>
                    </div>
                    <div className={'flex items-center mb-6 pb-2 border-b border-solid border-[#6E5C40]'}>
                        <CheckIcon className="w-10 h-10 mr-2"/>
                        <h4 className={'text-btn lg:text-h4'}>20 РОКІВ НА РИНКУ</h4>
                    </div>
                    <div className={'flex items-center mb-6 pb-2 border-b border-solid border-[#6E5C40]'}>
                        <CheckIcon className="w-10 h-10 mr-2"/>
                        <h4 className={'text-btn lg:text-h4'}>20 РОКІВ ГАРАНТІЇ</h4>
                    </div>
                    <div className={'flex items-center mb-6 pb-2 border-b border-solid border-[#6E5C40]'}>
                        <CheckIcon className="w-10 h-10 mr-2"/>
                        <h4 className={'text-btn lg:text-h4'}>ДОСТАВКА ПО ВСІЙ УКРАЇНІ</h4>
                    </div>
                </div>
                <div
                    className={`hidden relative w-full mt-6 lg:mt-0 max-w-screen-sm lg:flex lg:w-5/12 z-10 ${styles.bigStoneContainer}`}
                >
                    <img className="px-0 lg:px-6" src={BigStonePhoto} alt="BigStonePhoto"/>
                </div>
            </div>
            <OurAdvantagesSection/>
        </motion.section>
    );
}

export default HeroSection;
