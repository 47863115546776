import { ReactComponent as StarIcon } from "../../assets/images/icons/star-icon.svg";
import { ReactComponent as SearchIcon } from "../../assets/images/icons/search-icon.svg";
import { ReactComponent as AdjustmentIcon } from "../../assets/images/icons/adjustment-icon.svg";
import { ReactComponent as NavigationArrowIcon } from "../../assets/images/icons/navigation-arrow-icon.svg";
import { ReactComponent as ArrowDiagonalIcon } from "../../assets/images/icons/arrow-diagonal-icon.svg";
import NavigationArrow from "../NavigationArrow/NavigationArrow";
import React, { useState, useContext, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { OurProductsContext } from "../../context/OurProductsContext";

function CatalogSection(props, ref) {
  const { selectedItems, setSelectedItems, breadCrumbs, handleClickBack } =
    useContext(OurProductsContext);
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalSlides = selectedItems?.length;
  let slidesToShow;
  const sliderRef = useRef(null);

  if (window.innerWidth >= 1280) {
    slidesToShow = 3;
  } else if (window.innerWidth >= 768) {
    slidesToShow = 2;
  } else {
    slidesToShow = 1;
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentIndex(newIndex);
    },
  };

  function PrevArrow(props) {
    const { onClick } = props;
    return (
      <div className="absolute z-10 bottom-0 right-2/4 px-1 md:left-auto md:right-24 md:bottom-[-15px]">
        <NavigationArrow
          onLightBg={true}
          turnOverLeft={true}
          onClick={onClick}
        />
      </div>
    );
  }

  function NextArrow(props) {
    const { onClick } = props;
    return (
      <div className="absolute z-10 bottom-0 left-2/4 px-1 md:left-auto md:right-0 md:bottom-[-15px]">
        <NavigationArrow onLightBg={true} onClick={onClick} />
      </div>
    );
  }

  const progressBarStyle = {
    width: `${((currentIndex + slidesToShow) / totalSlides) * 100}%`,
  };

  const handleClickItem = (item) => {
    if (item.items && item.items.length) {
      setSelectedItems(item);
      slickGoToFist();
    } else {
      openImagePopup(item.image);
    }
  };

  const slickGoToFist = () => {
    if (sliderRef.current) {
      setTimeout(() => {
        sliderRef.current.slickGoTo(0, false);
      }, 100);
    }
  };

  const slideClass = (index) => {
    return `pr-4 md:pr-8 h-[400px] relative cursor-pointer ${
      slidesToShow === 1 || index + 1 === selectedItems.length
        ? "!pr-0 md:!pr-0"
        : ""
    }`;
  };

  const openImagePopup = (imageSrc) => {
    const popup = document.createElement("div");
    popup.className =
        "fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-50";
    popup.innerHTML = `
      <div class="relative max-h-[100vh]">
        <div class="rounded-full bg-black w-12 h-12 absolute top-2 right-2 cursor-pointer"> 
            <div class="flex items-center justify-center text-custom-white text-4xl ">&times;</div>
        </div>
        <img src="${imageSrc}" alt="Full Image" class="max-h-[100vh] mx-auto rounded-lg" />
      </div>
    `;
    document.body.appendChild(popup);

    popup.querySelector(".absolute").addEventListener("click", () => {
      document.body.removeChild(popup);
    });

    // Закрити попап при кліку поза контентом
    popup.addEventListener("click", (event) => {
      if (event.target === popup) {
        document.body.removeChild(popup);
      }
    });
  };

  return (
    <section className="pt-20" ref={ref}>
      <div className="flex items-center mb-6">
        <StarIcon className="w-4 h-4 lg:h-6 lg:w-6" />
        <span className="uppercase ml-2 lg:ml-4 text-custom-yellow text-editional">
          з натурального каменю
        </span>
      </div>
      <div>
        <div className="flex mb-3 h-16">
          {/* наразі вирішили не використовувати пошук, так як елементів не так багато */}
          {/* <div
            className={`${
              isShowInput ? "w-full" : ""
            } flex items-center cursor-pointer`}
          >
            <SearchIcon />
            {isShowInput ? (
              <input
                type="text"
                className="border-b ml-2 pt-1 pb-1 w-full border-custom-black bg-inherit border-solid text-editional"
                autoFocus={true}
                onBlur={() => setIsShowInput(false)}
                onChange={handleSearchChange} // Оновлення пошукового запиту
              />
            ) : (
              <span
                className="uppercase text-editional text-custom-black ml-2"
                onClick={() => setIsShowInput(true)}
              >
                пошук
              </span>
            )}
          </div> */}
          {/* <div className="flex items-center cursor-pointer w-full">
            <AdjustmentIcon />
            <span className="uppercase text-editional text-custom-black ml-2">
              фільтр за категоріями
            </span>
          </div> */}
          {breadCrumbs && breadCrumbs.length > 0 && (
              <div className="flex items-center">
              <div
                    className="flex items-center cursor-pointer h-10 px-5 bg-custom-black rounded-3xl z-10 mr-4"
                    onClick={() => {
                      handleClickBack();
                      slickGoToFist();
                    }}
                >
                  <div className="scale-x-[-1]">
                    <NavigationArrowIcon className="text-custom-offwhite"/>
                  </div>
                  <div className="ml-4 flex items-center uppercase text-small text-custom-offwhite">
                    назад
                  </div>
                </div>
                {/*{breadCrumbs.length > 1 ? (*/}
                {/*    <div className="text-custom-black text-small">*/}
                {/*      {breadCrumbs.join(" / ")}*/}
                {/*    </div>*/}
                {/*) : (*/}
                {/*    <div className="text-custom-black text-small">*/}
                {/*      {breadCrumbs[0]}*/}
                {/*    </div>*/}
                {/*)}*/}
              </div>
          )}
          {breadCrumbs.length ? (
              <div className="text-custom-black text-h3 flex items-center">
                {breadCrumbs[0]}
              </div>
          ) : (
              <div className="text-custom-black text-h2 flex items-center">
                Всі категорії
              </div>
          )}
        </div>
      </div>
      <div className="relative pb-16 md:pb-0">
        <Slider {...settings} className="h-[400px] !static" ref={sliderRef}>
          {selectedItems.map((item, index) => (
            <div className={slideClass(index)}>
              <img
                className="w-full h-full rounded-3xl object-cover"
                src={item.image}
                alt="SliderImage"
                onClick={() => handleClickItem(item)}
              />
              <div
                className="flex items-center absolute bottom-4 left-4 pr-4"
                onClick={() => handleClickItem(item)}
              >
                {item.name && (
                  <div className="bg-white rounded-3xl py-1 min-h-10 px-5 text-btn flex items-center">
                    {item.name}
                  </div>
                )}
                {item.items && (
                  <div className="flex justify-center items-center bg-white rounded-full w-10 h-10 ml-2">
                    <ArrowDiagonalIcon className="text-custom-black" />
                  </div>
                )}
              </div>
            </div>
          ))}
        </Slider>
        <div className="relative md:w-[calc(100%-248px)]">
          <div className="w-full bg-custom-black h-[1px] opacity-20 relative mt-10" />
          <div
            className="bg-custom-black opacity-100 h-[1px] absolute left-0 top-0"
            style={progressBarStyle}
          />
        </div>
      </div>
    </section>
  );
}

export default React.forwardRef(CatalogSection);
