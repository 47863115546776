// src/context/ProductsContext.js
import React, { useState, createContext, useEffect } from "react";
import INTERIOR_DECORATION_MAIN_IMG from "../assets/images/catalog/INTERIOR_DECORATION/INTERIOR_DECORATION_MAIN.jpg";
import INTERIOR_DECORATION_1_IMG from "../assets/images/catalog/INTERIOR_DECORATION/INTERIOR_DECORATION_1.jpg";
import INTERIOR_DECORATION_2_IMG from "../assets/images/catalog/INTERIOR_DECORATION/INTERIOR_DECORATION_2.jpg";
import INTERIOR_DECORATION_3_IMG from "../assets/images/catalog/INTERIOR_DECORATION/INTERIOR_DECORATION_3.jpg";
import INTERIOR_DECORATION_4_IMG from "../assets/images/catalog/INTERIOR_DECORATION/INTERIOR_DECORATION_4.jpg";
import INTERIOR_DECORATION_5_IMG from "../assets/images/catalog/INTERIOR_DECORATION/INTERIOR_DECORATION_5.jpg";
import INTERIOR_DECORATION_6_IMG from "../assets/images/catalog/INTERIOR_DECORATION/INTERIOR_DECORATION_6.jpg";
import INTERIOR_DECORATION_7_IMG from "../assets/images/catalog/INTERIOR_DECORATION/INTERIOR_DECORATION_7.jpg";
import INTERIOR_DECORATION_8_IMG from "../assets/images/catalog/INTERIOR_DECORATION/INTERIOR_DECORATION_8.jpg";
import INTERIOR_DECORATION_9_IMG from "../assets/images/catalog/INTERIOR_DECORATION/INTERIOR_DECORATION_9.jpg";
import INTERIOR_DECORATION_10_IMG from "../assets/images/catalog/INTERIOR_DECORATION/INTERIOR_DECORATION_10.jpg";
import INTERIOR_DECORATION_11_IMG from "../assets/images/catalog/INTERIOR_DECORATION/INTERIOR_DECORATION_11.jpg";
import INTERIOR_DECORATION_12_IMG from "../assets/images/catalog/INTERIOR_DECORATION/INTERIOR_DECORATION_12.jpg";
import INTERIOR_DECORATION_13_IMG from "../assets/images/catalog/INTERIOR_DECORATION/INTERIOR_DECORATION_13.jpg";
import INTERIOR_DECORATION_14_IMG from "../assets/images/catalog/INTERIOR_DECORATION/INTERIOR_DECORATION_14.jpg";
import INTERIOR_DECORATION_15_IMG from "../assets/images/catalog/INTERIOR_DECORATION/INTERIOR_DECORATION_15.jpg";
import INTERIOR_DECORATION_16_IMG from "../assets/images/catalog/INTERIOR_DECORATION/INTERIOR_DECORATION_16.jpg";
import INTERIOR_DECORATION_17_IMG from "../assets/images/catalog/INTERIOR_DECORATION/INTERIOR_DECORATION_17.jpg";
import INTERIOR_DECORATION_18_IMG from "../assets/images/catalog/INTERIOR_DECORATION/INTERIOR_DECORATION_18.jpg";
import INTERIOR_DECORATION_19_IMG from "../assets/images/catalog/INTERIOR_DECORATION/INTERIOR_DECORATION_19.jpg";
import INTERIOR_DECORATION_20_IMG from "../assets/images/catalog/INTERIOR_DECORATION/INTERIOR_DECORATION_20.jpg";
import INTERIOR_DECORATION_21_IMG from "../assets/images/catalog/INTERIOR_DECORATION/INTERIOR_DECORATION_21.jpg";
import INTERIOR_DECORATION_22_IMG from "../assets/images/catalog/INTERIOR_DECORATION/INTERIOR_DECORATION_22.jpg";
import INTERIOR_DECORATION_23_IMG from "../assets/images/catalog/INTERIOR_DECORATION/INTERIOR_DECORATION_23.jpg";
import INTERIOR_DECORATION_24_IMG from "../assets/images/catalog/INTERIOR_DECORATION/INTERIOR_DECORATION_24.jpg";
import INTERIOR_DECORATION_25_IMG from "../assets/images/catalog/INTERIOR_DECORATION/INTERIOR_DECORATION_25.jpg";
import INTERIOR_DECORATION_26_IMG from "../assets/images/catalog/INTERIOR_DECORATION/INTERIOR_DECORATION_26.jpg";
import INTERIOR_DECORATION_27_IMG from "../assets/images/catalog/INTERIOR_DECORATION/INTERIOR_DECORATION_27.jpg";
import EXTERIOR_DECORATION_MAIN_IMG from "../assets/images/catalog/EXTERIOR_DECORATION/EXTERIOR_DECORATION_MAIN.jpg";
import EXTERIOR_DECORATION_1_IMG from "../assets/images/catalog/EXTERIOR_DECORATION/EXTERIOR_DECORATION_1.jpg";
import EXTERIOR_DECORATION_2_IMG from "../assets/images/catalog/EXTERIOR_DECORATION/EXTERIOR_DECORATION_2.jpg";
import EXTERIOR_DECORATION_3_IMG from "../assets/images/catalog/EXTERIOR_DECORATION/EXTERIOR_DECORATION_3.jpg";
import EXTERIOR_DECORATION_4_IMG from "../assets/images/catalog/EXTERIOR_DECORATION/EXTERIOR_DECORATION_4.jpg";
import EXTERIOR_DECORATION_5_IMG from "../assets/images/catalog/EXTERIOR_DECORATION/EXTERIOR_DECORATION_5.jpg";
import EXTERIOR_DECORATION_6_IMG from "../assets/images/catalog/EXTERIOR_DECORATION/EXTERIOR_DECORATION_6.jpg";
import EXTERIOR_DECORATION_7_IMG from "../assets/images/catalog/EXTERIOR_DECORATION/EXTERIOR_DECORATION_7.jpg";
import CHURCH_THEME_MAIN_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_MAIN.jpg";
import CHURCH_THEME_1_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_1.jpeg";
import CHURCH_THEME_2_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_2.jpeg";
import CHURCH_THEME_3_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_3.jpeg";
import CHURCH_THEME_4_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_4.jpeg";
import CHURCH_THEME_5_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_5.jpeg";
import CHURCH_THEME_6_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_6.jpeg";
import CHURCH_THEME_7_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_7.jpeg";
import CHURCH_THEME_8_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_8.jpeg";
import CHURCH_THEME_9_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_9.jpeg";
import CHURCH_THEME_10_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_10.jpeg";
import CHURCH_THEME_11_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_11.jpeg";
import CHURCH_THEME_12_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_12.jpeg";
import CHURCH_THEME_13_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_13.jpeg";
import CHURCH_THEME_14_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_14.jpeg";
import CHURCH_THEME_15_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_15.jpeg";
import CHURCH_THEME_16_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_16.jpeg";
import CHURCH_THEME_17_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_17.jpeg";
import CHURCH_THEME_18_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_18.jpeg";
import CHURCH_THEME_19_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_19.jpeg";
import CHURCH_THEME_20_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_20.jpeg";
import CHURCH_THEME_21_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_21.jpeg";
import CHURCH_THEME_22_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_22.jpeg";
import CHURCH_THEME_23_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_23.jpeg";
import CHURCH_THEME_24_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_24.jpeg";
import CHURCH_THEME_25_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_25.jpeg";
import CHURCH_THEME_26_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_26.jpeg";
import CHURCH_THEME_27_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_27.jpeg";
import CHURCH_THEME_28_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_28.jpeg";
import CHURCH_THEME_29_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_29.jpeg";
import CHURCH_THEME_30_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_30.jpeg";
import CHURCH_THEME_31_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_31.jpeg";
import CHURCH_THEME_32_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_32.jpeg";
import CHURCH_THEME_33_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_33.jpeg";
import CHURCH_THEME_34_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_34.jpeg";
import CHURCH_THEME_35_IMG from "../assets/images/catalog/CHURCH_THEME/CHURCH_THEME_35.jpeg";
import OTHER_MAIN_IMG from "../assets/images/catalog/OTHER/OTHER_MAIN.jpg";
import OTHER_1_IMG from "../assets/images/catalog/OTHER/OTHER_1.jpg";
import OTHER_2_IMG from "../assets/images/catalog/OTHER/OTHER_2.jpg";
import OTHER_3_IMG from "../assets/images/catalog/OTHER/OTHER_3.jpg";
import OTHER_5_IMG from "../assets/images/catalog/OTHER/OTHER_5.jpg";
import OTHER_6_IMG from "../assets/images/catalog/OTHER/OTHER_6.jpg";
import OTHER_7_IMG from "../assets/images/catalog/OTHER/OTHER_7.jpg";
import OTHER_8_IMG from "../assets/images/catalog/OTHER/OTHER_8.jpg";
import OTHER_9_IMG from "../assets/images/catalog/OTHER/OTHER_9.jpg";
import OTHER_10_IMG from "../assets/images/catalog/OTHER/OTHER_10.jpg";
import OTHER_11_IMG from "../assets/images/catalog/OTHER/OTHER_11.jpg";
import OTHER_12_IMG from "../assets/images/catalog/OTHER/OTHER_12.jpg";
import OTHER_13_IMG from "../assets/images/catalog/OTHER/OTHER_13.jpg";
import OTHER_14_IMG from "../assets/images/catalog/OTHER/OTHER_14.jpg";
import OTHER_15_IMG from "../assets/images/catalog/OTHER/OTHER_15.jpg";
import OTHER_16_IMG from "../assets/images/catalog/OTHER/OTHER_16.jpg";
import OTHER_17_IMG from "../assets/images/catalog/OTHER/OTHER_17.jpg";
import OTHER_18_IMG from "../assets/images/catalog/OTHER/OTHER_18.jpg";
import OTHER_19_IMG from "../assets/images/catalog/OTHER/OTHER_19.jpg";
import OTHER_20_IMG from "../assets/images/catalog/OTHER/OTHER_20.jpg";
import OTHER_21_IMG from "../assets/images/catalog/OTHER/OTHER_21.jpg";
import OTHER_22_IMG from "../assets/images/catalog/OTHER/OTHER_22.jpg";
import LANDSCAPE_MAIN_IMG from "../assets/images/catalog/LANDSCAPE/LANDSCAPE_MAIN.jpg";
import LANDSCAPE_1_IMG from "../assets/images/catalog/LANDSCAPE/LANDSCAPE_1.jpg";
import LANDSCAPE_2_IMG from "../assets/images/catalog/LANDSCAPE/LANDSCAPE_2.jpg";
import LANDSCAPE_3_IMG from "../assets/images/catalog/LANDSCAPE/LANDSCAPE_3.jpg";

const generateId = () => {
  return Math.floor(100000000 + Math.random() * 900000000);
};

export const OurProductsItems = [
  {
    id: generateId(),
    name: "Ритуальні вироби",
    image: CHURCH_THEME_MAIN_IMG,
    description: "ПАМʼЯТНИКИ, СКЛЕПИ, СТЕЛИ, НАДГРОБНІ ПЛИТИ, СКУЛЬПТУРИ",
    items: [
      { image: CHURCH_THEME_1_IMG },
      { image: CHURCH_THEME_2_IMG },
      { image: CHURCH_THEME_3_IMG },
      { image: CHURCH_THEME_4_IMG },
      { image: CHURCH_THEME_5_IMG },
      { image: CHURCH_THEME_6_IMG },
      { image: CHURCH_THEME_7_IMG },
      { image: CHURCH_THEME_8_IMG },
      { image: CHURCH_THEME_9_IMG },
      { image: CHURCH_THEME_10_IMG },
      { image: CHURCH_THEME_11_IMG },
      { image: CHURCH_THEME_12_IMG },
      { image: CHURCH_THEME_13_IMG },
      { image: CHURCH_THEME_14_IMG },
      { image: CHURCH_THEME_15_IMG },
      { image: CHURCH_THEME_16_IMG },
      { image: CHURCH_THEME_17_IMG },
      { image: CHURCH_THEME_18_IMG },
      { image: CHURCH_THEME_19_IMG },
      { image: CHURCH_THEME_20_IMG },
      { image: CHURCH_THEME_21_IMG },
      { image: CHURCH_THEME_22_IMG },
      { image: CHURCH_THEME_23_IMG },
      { image: CHURCH_THEME_24_IMG },
      { image: CHURCH_THEME_25_IMG },
      { image: CHURCH_THEME_26_IMG },
      { image: CHURCH_THEME_27_IMG },
      { image: CHURCH_THEME_28_IMG },
      { image: CHURCH_THEME_29_IMG },
      { image: CHURCH_THEME_30_IMG },
      { image: CHURCH_THEME_31_IMG },
      { image: CHURCH_THEME_32_IMG },
      { image: CHURCH_THEME_33_IMG },
      { image: CHURCH_THEME_34_IMG },
      { image: CHURCH_THEME_35_IMG },
    ],
  },
  {
    id: generateId(),
    name: "Зовнішнє оздоблення",
    image: EXTERIOR_DECORATION_MAIN_IMG,
    description:
        "ФАСАДИ, АЛЬТАНКИ, КОЛОНИ, СКУЛЬПТУРИ",
    items: [
      { image: EXTERIOR_DECORATION_1_IMG },
      { image: EXTERIOR_DECORATION_2_IMG },
      { image: EXTERIOR_DECORATION_3_IMG },
      { image: EXTERIOR_DECORATION_4_IMG },
      { image: EXTERIOR_DECORATION_5_IMG },
      { image: EXTERIOR_DECORATION_6_IMG },
      { image: EXTERIOR_DECORATION_7_IMG },
    ],
  },
  {
    id: generateId(),
    name: "Внутрішнє оздоблення",
    image: INTERIOR_DECORATION_MAIN_IMG,
    description:
      "ВАННИ, РАКОВИНИ, СХОДИ, ПІДВІКОННЯ, КАМІНИ",
    items: [
      { image: INTERIOR_DECORATION_1_IMG },
      { image: INTERIOR_DECORATION_2_IMG },
      { image: INTERIOR_DECORATION_3_IMG },
      { image: INTERIOR_DECORATION_4_IMG },
      { image: INTERIOR_DECORATION_5_IMG },
      { image: INTERIOR_DECORATION_6_IMG },
      { image: INTERIOR_DECORATION_7_IMG },
      { image: INTERIOR_DECORATION_8_IMG },
      { image: INTERIOR_DECORATION_9_IMG },
      { image: INTERIOR_DECORATION_10_IMG },
      { image: INTERIOR_DECORATION_11_IMG },
      { image: INTERIOR_DECORATION_12_IMG },
      { image: INTERIOR_DECORATION_13_IMG },
      { image: INTERIOR_DECORATION_14_IMG },
      { image: INTERIOR_DECORATION_15_IMG },
      { image: INTERIOR_DECORATION_16_IMG },
      { image: INTERIOR_DECORATION_17_IMG },
      { image: INTERIOR_DECORATION_18_IMG },
      { image: INTERIOR_DECORATION_19_IMG },
      { image: INTERIOR_DECORATION_20_IMG },
      { image: INTERIOR_DECORATION_21_IMG },
      { image: INTERIOR_DECORATION_22_IMG },
      { image: INTERIOR_DECORATION_23_IMG },
      { image: INTERIOR_DECORATION_24_IMG },
      { image: INTERIOR_DECORATION_25_IMG },
      { image: INTERIOR_DECORATION_26_IMG },
      { image: INTERIOR_DECORATION_27_IMG },
    ],
  },
  {
    id: generateId(), // допрацювати, добавити картинки.
    name: "Ландшафт",
    image: LANDSCAPE_MAIN_IMG,
    description: "ДЕКОРАТИВНІ ЕЛЕМЕНТИ, ОГОРОЖІ, ФОНТАНИ",
    items: [
      { image: LANDSCAPE_1_IMG },
      { image: LANDSCAPE_2_IMG },
      { image: LANDSCAPE_3_IMG },
    ],
  },
  {
    id: generateId(),
    name: "Мармурові сляби/блоки",
    image: OTHER_MAIN_IMG,
    description: "СЛЯБИ, БЛОКИ, ГАЛЬКА, ПЛИТКА",
    items: [
      { image: OTHER_1_IMG },
      { image: OTHER_2_IMG },
      { image: OTHER_3_IMG },
      { image: OTHER_5_IMG },
      { image: OTHER_6_IMG },
      { image: OTHER_7_IMG },
      { image: OTHER_8_IMG },
      { image: OTHER_9_IMG },
      { image: OTHER_10_IMG },
      { image: OTHER_11_IMG },
      { image: OTHER_12_IMG },
      { image: OTHER_13_IMG },
      { image: OTHER_14_IMG },
      { image: OTHER_15_IMG },
      { image: OTHER_16_IMG },
      { image: OTHER_17_IMG },
      { image: OTHER_18_IMG },
      { image: OTHER_19_IMG },
      { image: OTHER_20_IMG },
      { image: OTHER_21_IMG },
      { image: OTHER_22_IMG },
    ],
  },
];

export const OurProductsContext = createContext();

export const OurProductsProvider = ({ children }) => {
  const [allItems] = useState(OurProductsItems);
  const [filteredOrSelectedItems, setFilteredOrSelectedItems] =
    useState(allItems);
  const [breadCrumbs, setBreadCrumbs] = useState([]);

  useEffect(() => {
    setFilteredOrSelectedItems(allItems);
    setSelectedItems(allItems[0])
  }, [allItems]);

  const setSelectedItems = (item) => {
    if (item) {
      setFilteredOrSelectedItems(item.items);
      setBreadCrumbs((prevBreadCrumbs) => [...prevBreadCrumbs, item.name]);
    } else {
      setFilteredOrSelectedItems(allItems);
      clearBreadCrumbs();
    }
  };

  const handleClickBack = () => {
    if (breadCrumbs.length === 0) return;

    const newBreadCrumbs = [...breadCrumbs];
    newBreadCrumbs.pop();

    let prevItems = allItems;

    newBreadCrumbs.forEach((crumb) => {
      const foundItem = prevItems.find((item) => item.name === crumb);
      if (foundItem) {
        prevItems = foundItem.items;
      }
    });

    setFilteredOrSelectedItems(prevItems);
    setBreadCrumbs(newBreadCrumbs);
  };

  const clearBreadCrumbs = () => {
    setBreadCrumbs([]);
  };

  return (
    <OurProductsContext.Provider
      value={{
        selectedItems: filteredOrSelectedItems,
        setSelectedItems,
        breadCrumbs,
        handleClickBack,
        clearBreadCrumbs,
      }}
    >
      {children}
    </OurProductsContext.Provider>
  );
};
