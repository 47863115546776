import React, { useState, useEffect } from "react";
import { ReactComponent as Logo } from "../../assets/images/logo/logo.svg";
import PhoneButton from "../../components/PhoneButton/PhoneButton";
import { motion } from "framer-motion";

const Header = ({
                    ourProjectsRef,
                    aboutUs,
                    ourServicesRef,
                    catalogRef,
                    typesOfStoneRef,
                    reviewsRef,
                }) => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 50);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const handleNavClick = (elementId) => {
        elementId.current.scrollIntoView({ behavior: "smooth" });
        setIsMenuOpen(false); // Закриваємо меню після кліку
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen); // Тогл бургер-меню
    };

    const nav = [
        { name: "Ритуальні вироби", elementId: catalogRef },
        { name: "Наші роботи", elementId: ourServicesRef },
        { name: "Види каменю", elementId: typesOfStoneRef },
        { name: "Відгуки", elementId: reviewsRef },
        { name: "Про компанію", elementId: aboutUs },
    ];

    return (
        <motion.header
            initial={{ top: "-200px" }}
            animate={{
                top: 0,
            }}
            style={{
                backgroundColor: "rgba(241, 239, 236, .9)",
            }}
            transition={{ duration: 1, ease: "easeOut" }}
            className="fixed z-50 left-0 right-0 px-4 flex items-center justify-between py-4 lg:py-6 lg:px-12"
        >
            <div className="flex items-center justify-between w-full lg:w-max">
                <div className="w-8 h-8 cursor-pointer mr-4" onClick={scrollToTop}>
                    <Logo className="w-full h-full" />
                </div>
                <div className="hidden md:flex md:flex-col">
                    <PhoneButton />
                    <p className="text-small">м.Київ вул.Визволителів 17</p>
                </div>
                {/* Бургер-меню для мобільних */}
                <div className="lg:hidden">
                    <button
                        className="focus:outline-none"
                        onClick={toggleMenu}
                    >
                        <span className="block w-6 h-0.5 bg-black mb-1"></span>
                        <span className="block w-6 h-0.5 bg-black mb-1"></span>
                        <span className="block w-6 h-0.5 bg-black"></span>
                    </button>
                </div>
            </div>
            {/* Меню для великих екранів */}
            <div className="hidden lg:flex justify-between w-full max-w-[700px]">
                {nav.map((item, index) => (
                    <div
                        key={index}
                        onClick={() => handleNavClick(item.elementId)}
                        className="cursor-pointer text-custom-black text-medium mx-2"
                    >
                        {item.name}
                    </div>
                ))}
            </div>
            {/* Бургер-меню для мобільних */}
            <div
                className={`${
                    isMenuOpen ? "block" : "hidden"
                    
                } absolute py-4 top-full rounded-b-2xl left-0 w-full border-solid border lg:hidden`}
                style={{
                    backgroundColor: "rgba(241, 239, 236, .9)",
                }}
            >
                {nav.map((item, index) => (
                    <div
                        key={index}
                        onClick={() => handleNavClick(item.elementId)}
                        className="cursor-pointer text-custom-black text-medium px-4 py-2"
                    >
                        {item.name}
                    </div>
                ))}
            </div>
        </motion.header>
    );
};

export default Header;
