import React, {useState, useEffect} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NavigationArrow from "../NavigationArrow/NavigationArrow";
import {ReactComponent as MenuIcon} from "../../assets/images/icons/menu-icon.svg";
import {ReactComponent as StarIcon} from "../../assets/images/icons/star-icon.svg";
import DirectorOfWhiteAgency from "../../assets/images/photos/user.png";
import ProfileIcon1 from "../../assets/images/photos/profile_1.png";
import ProfileIcon2 from "../../assets/images/photos/profile_2.png";
import ProfileIcon3 from "../../assets/images/photos/profile_3.png";
import ProfileIcon4 from "../../assets/images/photos/profile_4.png";
import ProfileIcon5 from "../../assets/images/photos/profile_5.png";

function daysAgo(timestamp) {
    const now = new Date();
    const date = new Date(timestamp * 1000);
    const diff = now - date;
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));

    return days;
}

const PLACE_ID = 'ChIJu1m5PBbP1EARq4fpZHojFPw';
const KEY_ID = 'AIzaSyCyFIMUonvUAo0WxBxaRNC93KCPLVBvkWc'

function ReviewsSection(props, ref) {
    const slider = React.useRef(null);
    const [reviews, setReviews] = useState([]);

    const sliderItems =
        [
            {
                "author_name": "Алина Барских",
                "profile_photo_url": ProfileIcon1,
                "rating": 5,
                "relative_time_description": "Декілька днів тому",
                "text": "Укладывали мраморный пол в гостиной и очень довольны конечным результатом. Помогли подобрать цвет, предоставили образцы, работу проделали вовремя в оговоренный срок. Спасибо!",
            },
            {
                "author_name": "Влада Сафронова",
                "profile_photo_url": ProfileIcon2,
                "rating": 5,
                "relative_time_description": "2 місяці тому",
                "text": "Заказывала раковину, отлично подобрали камень под интерьер дома, очень благодарна",
            },
            {
                "author_name": "Алексей Наумов",
                "profile_photo_url": ProfileIcon3,
                "rating": 5,
                "relative_time_description": "2 місяці тому",
                "text": "Учли все пожелания, быстро и качественно, спасибо за проделанную работу, рекомендую!",
            },
            {
                "author_name": "Василий Косенко",
                "profile_photo_url": ProfileIcon4,
                "rating": 5,
                "relative_time_description": "3 місяці тому",
                "text": "Заказывали столешницу из натурального камня, остались довольны на все 100%",
            },
            {
                "author_name": "Максим Папуш",
                "profile_photo_url": ProfileIcon5,
                "rating": 5,
                "relative_time_description": "Пів року тому",
                "text": "Большой склад мрамора. Сотрудники показали ассортимент и помогли с выбором. Спасибо!",
            }
        ];

    useEffect(() => {
        const loadGoogleMapsApi = () => {
            return new Promise((resolve, reject) => {
                if (window.google && window.google.maps) {
                    resolve(window.google);
                } else {
                    const script = document.createElement('script');
                    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCyFIMUonvUAo0WxBxaRNC93KCPLVBvkWc&libraries=places`;
                    script.async = true;
                    script.onload = () => resolve(window.google);
                    script.onerror = () => reject(new Error('Failed to load Google Maps API'));
                    document.head.appendChild(script);
                }
            });
        };

        const fetchReviews = () => {
            loadGoogleMapsApi()
                .then((google) => {
                    const service = new google.maps.places.PlacesService(document.createElement('div'));
                    const request = {
                        placeId: PLACE_ID,
                        fields: ['reviews'],
                    };
                    service.getDetails(request, (place, status) => {
                        if (status === google.maps.places.PlacesServiceStatus.OK) {
                            const sortArray = place.reviews?.sort((a, b) => b.time - a.time);
                            setReviews(sortArray || sliderItems);
                        } else {
                            setReviews(sliderItems);
                        }
                    });
                })
                .catch((err) => {
                    setReviews(sliderItems);
                });
        };

        fetchReviews();
    }, []);

    function PrevArrow(props) {
        const {onClick} = props;
        return (
            <div className="absolute z-10 bottom-6 right-2/4 px-1 md:bottom-12 md:left-12">
                <NavigationArrow turnOverLeft={true} onClick={onClick}/>
            </div>
        );
    }

    function NextArrow(props) {
        const {onClick} = props;
        return (
            <div className="absolute z-10 bottom-6 left-2/4 px-1 md:bottom-12 md:left-36">
                <NavigationArrow onClick={onClick}/>
            </div>
        );
    }

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow/>,
        prevArrow: <PrevArrow/>,
    };
    return (
        reviews && reviews.length > 0 && (
            <section className="pt-16 " ref={ref}>
                <div className="slider-container relative bg-custom-black rounded-2xl">
                    <Slider ref={slider} {...settings} className="flex">
                        {reviews &&
                            reviews.map((item, index) => (
                                <React.Fragment key={index}>
                                    <div className="flex flex-col max-h-full md:flex-row">
                                        <div className="py-6 px-4 md:w-3/6 md:p-12">
                                            <div className="flex items-center mb-6">
                                                <MenuIcon className="w-4 h-4 lg:h-6 lg:w-6"/>
                                                <span
                                                    className="uppercase ml-2 lg:ml-4 text-custom-yellow text-editional">
                        Відгуки
                      </span>
                                            </div>
                                            <h2 className="text-h2 uppercase text-custom-offwhite md:mb-6 md:max-w-[500px]">
                                                Що говорять наші клієнти
                                            </h2>
                                        </div>
                                        <div className="flex flex-col justify-between py-2 px-4 md:w-3/6 md:p-12">
                                            <div
                                                className="bg-custom-offwhite rounded-2xl flex items-center py-2 px-6 w-max">
                      <span className="text-medium font-bold text-custom-black mr-1">
                        {item.rating}
                      </span>
                                                <StarIcon className="w-4 h-4 text-custom-black lg:h-6 lg:w-6"/>
                                            </div>
                                            <p className="text-h4 text-custom-offwhite my-10">
                                                {item.text}
                                            </p>
                                            <div className="flex mb-24 md:mb-0">
                                                <img
                                                    className="w-14 h-14"
                                                    src={item.profile_photo_url || DirectorOfWhiteAgency}
                                                    alt="profile"
                                                    onError={(e) => {
                                                        e.target.onerror = null;
                                                        e.target.src = DirectorOfWhiteAgency;
                                                    }}
                                                />
                                                <div className="flex flex-col justify-between py-2 pl-4 md:pl-6">
                                                    <h5 className="text-custom-offwhite text-editional_bold">
                                                        {item.author_name}
                                                    </h5>
                                                    <span className="text-custom-offwhite text-editional opacity-70">
                           {
                               item.time ? (daysAgo(item.time) >= 1 ? `${daysAgo(item.time)} днів тому` : 'Сьогодні') : item.relative_time_description
                           }
                        </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))}
                    </Slider>
                </div>
            </section>
        )
    );
}

export default React.forwardRef(ReviewsSection);
